
import { getSliceComponentProps } from '@prismicio/vue/components';
import quoteAdapter from '@/slices/Quote/adapter';

export default {
  name: 'Quote',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    sliceData() {
      return quoteAdapter(this.slice);
    },
  },
};
